<script>
import { mapGetters, mapMutations } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			name: 'analisis_industria',
			form: {
				riskPortfolioViewSector: '',
				riskPortfolioViewSubsector: '',
				regulacionesAlSector: '',
				nivelCompentenciaSector: '',
				comentariosNivelCompetencia: '',
				nivelConcentracionEmpresasSector: '',
				comentariosConcentracionSector: '',
				resumenEvaluacionSectorial: ''

			},
		}
	},
	watch: {
		'client': {
			handler(nVal) {
				this.fillForm()
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		fillForm() {
			let data = {}
			if(this.client.arce.hasOwnProperty(this.name)) {
				data = {...this.client.arce[this.name]}
			}

			if(!Object.keys(data).length) {
				this.$set(this, 'form', {
					riskPortfolioViewSector: '',
					riskPortfolioViewSubsector: '',
					regulacionesAlSector: '',
					nivelCompentenciaSector: '',
					nivelConcentracionEmpresasSector: '',
					comentariosNivelCompetencia: '',
					comentariosConcentracionSector: '',
					resumenEvaluacionSectorial: ''
				})
				return 
			}


			this.$set(this, 'form', {...data})
		},
		calcFill() {
			let fields = Object.keys(this.form)
			if(this.form.regulacionesAlSector != 'Afectan a la compañía') {
				let index = fields.indexOf('comentariosNivelCompetencia')
				fields.splice(index, 1)
			}

			let points = 100 / fields.length

			let fill = 0

			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		
		save() {
			let vm = this
			let payload =  {
				client:  this.client,
				form: this.name,
				data: Object.assign({...this.form}, {}),
				fill: vm.calcFill()
			}
			vm._setDataChild(payload)
			vm.$emit('updateArce')
		},

		addNew() {
			this.shareholders.push(
				{
					nombre: '',
					participacion: '',
					financialSponsor: '',
					ano: '',
					query: ''
				}
			)
		},
		deleteItem(index) {
			this.shareholders.splice(index, 1)
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},

		saveAllShareholders() {
			this.$set(this.client, 'shareholders',  [...this.shareholders])
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'The record has been saved successfully'
			})
		},
		closeModal() {
			this.hiddenSteps()
			this.fillForm()
		},
		...mapMutations({
			_setDataChild: 'arceDetails/setDataChild'
		})
		// ...mapMutations({
		// 	setShareholders: 'arceDetails/setShareholders'
		// })
	},
	mounted() {
		$('#formAnalysisIndutryModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formAnalysisIndutryModal').off('hide.bs.modal', this.closeModal)
	}
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
			<button class="s-btn s-btn--primary" @click="showModal('#formAnalysisIndutryModal')">
				Editar
			</button>
		</div>
		<div>
			<div class="s-collapse__header" data-toggle="collapse" data-target="#c_ai_n_1" aria-expanded="true" aria-controls="c_ai_n_1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Outlook</span></h6>
				</div>
			</div>
			<div id="c_ai_n_1" class="collapse show" aria-labelledby="headingOne">

				<div class="d-flex flex-wrap">
						
						<div class="col-3">
							<label class="d-block m-0">
								<p class="m-0">Sector</p>
								<input class="form-control form-control--disabled" disabled type="text" value="Estable">
							</label>
						</div>
						
						<div class="col-3">
							<label class="d-block m-0">
								<p class="m-0">Geografía</p>
								<input class="form-control form-control--disabled" disabled type="text" value="Estable Negativo">
							</label>
						</div>
						<div class="col-3">
							<label class="d-block m-0">
								<p class="m-0">Risk portfolio view sector</p>
								<select class="form-control d-block form-control--disabled" disabled v-model="form.riskPortfolioViewSector">
									<option value=""></option>
									<option value="Neutral">Neutral</option>
									<option value="Underweight">Underweight</option>
									<option value="Overweight">Overweight</option>
									<option value="Reduce">Reduce</option>
								</select>
							</label>
						</div>
						

					</div>
					<div class="d-flex mt-3">
						<div class="col-3">
							<label class="d-block m-0">
								<p class="m-0">Risk portfolio view subsector</p>
								<select class="form-control d-block form-control--disabled" disabled v-model="form.riskPortfolioViewSubsector">
									<option value=""></option>
									<option value="Neutral">Neutral</option>
									<option value="Underweight">Underweight</option>
									<option value="Overweight">Overweight</option>
									<option value="Reduce">Reduce</option>
								</select>
							</label>
						</div>
						<div class="col-6">
							<a href="/fortimax.png" target="_blank">Link al documento para consultar el Risk portfolio view http://drive.google.com/drive/folders/213Yqa21u </a>
						</div>
					</div>
			</div>

			<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_ai_n_2" aria-expanded="true" aria-controls="c_ai_n_2">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Activo</span></h6>
				</div>
			</div>
			<div id="c_ai_n_2" class="collapse hide" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">

					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Exposición de Riesgos Total del sector (País)</p>
							<input class="form-control form-control--disabled" disabled type="text" value="27,353,865,272.21">
						</label>
					</div>
					
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Fecha de Exposición de Riesgos Total del Sector</p>
							<input class="form-control form-control--disabled" disabled type="text" value="2019-08-31">
						</label>
					</div>

					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Límite Asset Allocation del Sector (País)</p>
							<input class="form-control form-control--disabled" disabled type="text" value="21,529,000.00">
						</label>
					</div>
					
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Fecha Asset Allocation</p>
							<input class="form-control form-control--disabled" disabled type="text" value="2019-05-31">
						</label>
					</div>
					
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Disponibilidad del Crecimiento dentro del Sector</p>
							<input class="form-control form-control--disabled" disabled type="text" value="-5,824,865,272.21">
						</label>
					</div>
					
				</div>
			</div>

			<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_ai_n_3" aria-expanded="true" aria-controls="c_ai_n_3">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Probabilidad de incumplimiento</span></h6>
				</div>
			</div>

			<div id="c_ai_n_3" class="collapse hide" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">PI Media de Sector</p>
							<input class="form-control form-control--disabled" disabled type="text" value="6.06">
						</label>
					</div>
					
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Fecha PI Media del Sector</p>
							<input class="form-control form-control--disabled" disabled type="text" value="2019-12-31">
						</label>
					</div>
					
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">PI del cliente</p>
							<input class="form-control form-control--disabled" disabled type="text" value="">
						</label>
					</div>
					
					<div class="col-4 p-2">
						<label class="d-block m-0">
							<p class="m-0">Fecha de la PI del Cliente</p>
							<input class="form-control form-control--disabled" disabled type="text" value="">
						</label>
					</div>
					
				</div>
			</div>
			<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_ai_n_4" aria-expanded="true" aria-controls="c_ai_n_4">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Características del sector</span></h6>
				</div>
			</div>
			<div id="c_ai_n_4" class="collapse hide" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap">
					<div class="col-4 p-2">
						<p class="m-0"><span class="text-danger">*</span> ¿Existen Regulaciones al Sector que afecte a la Compañía?</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="form.regulacionesAlSector">

						
					</div>
					
					<div class="col-4 p-2">
						<p class="m-0"><span class="text-danger">*</span> ¿Cuál es el Nivel de Competencia del Sector?</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="form.nivelCompentenciaSector">
						<div v-if="form.nivelCompentenciaSector">
							<label class="d-block p-0 m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentarios Nivel de Competencia del Sector</p>
								<textarea class="form-control w-100 d-block form-control--disabled" disabled  rows="3" v-model="form.comentariosNivelCompetencia"></textarea>
							</label>
						</div>
					</div>
				
					<div class="col-4 p-2">
						<p class="m-0"><span class="text-danger">*</span> ¿Nivel de Concentración de algunas empresas en el Sector?</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="form.nivelConcentracionEmpresasSector">

						<div v-if="form.nivelConcentracionEmpresasSector">
							<label class="d-block p-0 m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentarios sobre Concentración en el sector</p>
								<textarea class="form-control w-100 d-block form-control--disabled" disabled rows="3" v-model="form.comentariosConcentracionSector"></textarea>
							</label>
						</div>
					</div>

					<div class="col-4 p-2">
						<label class="d-block p-0 m-0">
							<p class="m-0">Este apartado, resume la evaluación sectorial</p>
							<textarea class="form-control w-100 d-block  form-control--disabled" disabled  rows="3" v-model="form.resumenEvaluacionSectorial"></textarea>
						</label>
					</div>
					<div class="col-4 p-2">
						<a href="#" target="_blank">Resume el análisis de tu cliente de acuerdo con el sector indicado en el Outlook en análisis de la industria y calificado conforme documento global. Pulsa AQUÍ para consultar el documento global sectorial: http://drive.google.com/drive/folders/213Yqa21u </a>
					</div>

				</div>
			</div>
			
		</div>

		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formAnalysisIndutryModal" tabindex="-1" role="dialog" aria-labelledby="formAnalysisIndutryModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="s-collapse__header" data-toggle="collapse" data-target="#c_ai_1" aria-expanded="true" aria-controls="c_ai_1">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Outlook</span></h6>
						</div>
					</div>
					<div id="c_ai_1" class="collapse show" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap">
						
							<div class="col-3">
								<label class="d-block m-0">
									<p class="m-0">Sector</p>
									<input class="form-control form-control--disabled" disabled type="text" value="Estable">
								</label>
							</div>
							
							<div class="col-3">
								<label class="d-block m-0">
									<p class="m-0">Geografía</p>
									<input class="form-control form-control--disabled" disabled type="text" value="Estable Negativo">
								</label>
							</div>
							<div class="col-3">
								<label class="d-block m-0">
									<p class="m-0">Risk portfolio view sector</p>
									<select class="form-control d-block" v-model="form.riskPortfolioViewSector">
										<option value=""></option>
										<option value="Neutral">Neutral</option>
										<option value="Underweight">Underweight</option>
										<option value="Overweight">Overweight</option>
										<option value="Reduce">Reduce</option>
									</select>
								</label>
							</div>
							

						</div>
						<div class="d-flex mt-3">
							<div class="col-3">
								<label class="d-block m-0">
									<p class="m-0">Risk portfolio view subsector</p>
									<select class="form-control d-block" v-model="form.riskPortfolioViewSubsector">
										<option value=""></option>
										<option value="Neutral">Neutral</option>
										<option value="Underweight">Underweight</option>
										<option value="Overweight">Overweight</option>
										<option value="Reduce">Reduce</option>
									</select>
								</label>
							</div>
							<div class="col-6">
								<a href="/fortimax.png" target="_blank">Link al documento para consultar el Risk portfolio view http://drive.google.com/drive/folders/213Yqa21u </a>
							</div>
						</div>
					</div>

					<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_ai_2" aria-expanded="true" aria-controls="c_ai_2">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Activo</span></h6>
						</div>
					</div>
					<div id="c_ai_2" class="collapse hide" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap">

							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Exposición de Riesgos Total del sector (País)</p>
									<input class="form-control form-control--disabled" disabled type="text" value="27,353,865,272.21">
								</label>
							</div>
							
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Fecha de Exposición de Riesgos Total del Sector</p>
									<input class="form-control form-control--disabled" disabled type="text" value="2019-08-31">
								</label>
							</div>

							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Límite Asset Allocation del Sector (País)</p>
									<input class="form-control form-control--disabled" disabled type="text" value="21,529,000.00">
								</label>
							</div>
							
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Fecha Asset Allocation</p>
									<input class="form-control form-control--disabled" disabled type="text" value="2019-05-31">
								</label>
							</div>
							
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Disponibilidad del Crecimiento dentro del Sector</p>
									<input class="form-control form-control--disabled" disabled type="text" value="-5,824,865,272.21">
								</label>
							</div>
							
						</div>
					</div>

					<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_ai_3" aria-expanded="true" aria-controls="c_ai_3">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Probabilidad de incumplimiento</span></h6>
						</div>
					</div>

					<div id="c_ai_3" class="collapse hide" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap">
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">PI Media de Sector</p>
									<input class="form-control form-control--disabled" disabled type="text" value="6.06">
								</label>
							</div>
							
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Fecha PI Media del Sector</p>
									<input class="form-control form-control--disabled" disabled type="text" value="2019-12-31">
								</label>
							</div>
							
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">PI del cliente</p>
									<input class="form-control form-control--disabled" disabled type="text" value="">
								</label>
							</div>
							
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Fecha de la PI del Cliente</p>
									<input class="form-control form-control--disabled" type="text" value="">
								</label>
							</div>
							
						</div>
					</div>
					<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c_ai_4" aria-expanded="true" aria-controls="c_ai_4">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Características del sector</span></h6>
						</div>
					</div>
					<div id="c_ai_4" class="collapse hide" aria-labelledby="headingOne">
						<div class="d-flex flex-wrap">
							<div class="col-4 p-2">
								<p class="m-0"><span class="text-danger">*</span> ¿Existen Regulaciones al Sector que afecte a la Compañía?</p>
								<select class="form-control d-block"   v-model="form.regulacionesAlSector">'
										<option value="" disabled></option>
										<option value="Sin implcaciones">Sin implcaciones</option>
										<option value="Afectan a la compañía">Afectan a la compañía</option>
								</select>

								
							</div>
							
							<div class="col-4 p-2">
								<p class="m-0"><span class="text-danger">*</span> ¿Cuál es el Nivel de Competencia del Sector?</p>
								<select class="form-control d-block"   v-model="form.nivelCompentenciaSector">'
										<option value="" disabled></option>
										<option value="Alto">Alto</option>
										<option value="Medio">Medio</option>
										<option value="Bajo">Bajo</option>
								</select>
								<div v-if="form.nivelCompentenciaSector">
									<label class="d-block p-0 m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios Nivel de Competencia del Sector</p>
										<textarea class="form-control w-100 d-block" rows="3" v-model="form.comentariosNivelCompetencia"></textarea>
										<characters-available :max="1000" :text="form.comentariosNivelCompetencia"></characters-available>
									</label>
								</div>

								<!-- <div v-if="form.nivelCompentenciaSector">
									<label class="d-block p-0 m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios sobre la competencia en el sector</p>
										<textarea class="form-control w-100 d-block" rows="3" v-model="form.comentariosCompetenciaSector"></textarea>
									</label>
								</div> -->
							</div>
						
							<div class="col-4 p-2">
								<p class="m-0"><span class="text-danger">*</span> ¿Nivel de Concentración de algunas empresas en el Sector?</p>
								<select class="form-control d-block"   v-model="form.nivelConcentracionEmpresasSector">'
										<option value="" disabled></option>
										<option value="Alto">Alto</option>
										<option value="Medio">Medio</option>
										<option value="Bajo">Bajo</option>
								</select>

								<div v-if="form.nivelConcentracionEmpresasSector">
									<label class="d-block p-0 m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios sobre Concentración en el sector</p>
										<textarea class="form-control w-100 d-block" rows="3" v-model="form.comentariosConcentracionSector"></textarea>
										<characters-available :max="1000" :text="form.comentariosConcentracionSector"></characters-available>

									</label>
								</div>
							</div>

							<div class="col-4 p-2">
								<label class="d-block p-0 m-0">
									<p class="m-0">Este apartado, resume la evaluación sectorial</p>
									<textarea class="form-control w-100 d-block" rows="3" v-model="form.resumenEvaluacionSectorial"></textarea>
								</label>
							</div>
							<div class="col-4 p-2">
								<a href="#" target="_blank">Resume el análisis de tu cliente de acuerdo con el sector indicado en el Outlook en análisis de la industria y calificado conforme documento global. Pulsa AQUÍ para consultar el documento global sectorial: http://drive.google.com/drive/folders/213Yqa21u </a>
							</div>

						</div>
					</div>
					
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>
